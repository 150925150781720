import { Box } from '@mui/material';
import React from 'react';
import './App.css';
import Range from './components/range';

// a page app é apenas um exemplo de como usar o componente Range

function App() {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="App">
      <header className="App-header">
        <Box>
          <Range label="Label" value={value} handleChange={handleChange} />
        </Box>
      </header>
    </div>
  );
}

export default App;
