import { Box, Slider, Stack, Typography } from '@mui/material';
import React from 'react';
import theme from '../../styles/Theme';

export default function Range(props) {
  return (
    <Box sx={{ width: 100 }}>
      <Typography id="range-slider" gutterBottom sx={{ marginRight: '3em' }}>
        {props.label}
      </Typography>
      <Stack spacing={2} direction="row">
        <Slider
          value={props.value}
          onChange={props.handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={props.valuetext}
          borderColor={theme.palette.secondary.dark}
          sx={{
            color: theme.palette.secondary.dark,
            borderRadius: '10px',
            '& .MuiSlider-thumbSizeMedium': {
              width: '15px',
              height: '15px',
            },
          }}
        />
      </Stack>
    </Box>
  );
}
