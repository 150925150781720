import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#00796B',
      main: '#6FCF97',
      dark: '#004D40',
    },
    secondary: {
      light: '#CCE1DE',
      main: '#624768',
      dark: '#4C3751',
    },
    blue: {
      main: ' #56CCF2',
    },
    text: {
      primary: '#000000',
      main: '#1C1F1D',
      secondary: '#9B9B9B',
    },
    error: {
      primary: ' #EB5757',
      main: '#FC3C00',
      light: '#F68C70',
    },
    warning: {
      primary: '#F2C94C',
      light: '#999205',
      main: '#FEF205',
    },
    success: {
      main: '#00D34D',
    },
    grey: {
      50: '#FAFAFA',
      100: '#F0F0F0',
      200: '#EEEEEE',
      300: '#C4C4C4',
      400: '#9B9B9B',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
});

export default theme;
